import React, { useEffect, useState, useCallback } from 'react'
import '../assets/css/CodeEditorModal.css'
import useDimensions from "react-cool-dimensions"

import RestHelpers from '../helpers/RestHelpers'

import { DocumentVersionListItemModel } from '../model/VersionModel'
import { EditorDocumentModel, EditorDocumentEditingsModel } from '../model/DocumentModel'

import { Modal } from './Modal'
import { Tabs, Tab } from './Tabs'
import { LoadingSpinner } from './LoadingSpinner'
import { CodeEditor } from './CodeEditor'


const codeEditorTabs = ['schema', 'uischema', 'template'] as const
export type CodeEditorTab = typeof codeEditorTabs[number]

const codeEditorTabSettings: Array<Tab<CodeEditorTab>> = [
    { name: 'schema', title: 'Schema' },
    { name: 'uischema', title:  'UI Schema' },
    { name: 'template', title:  'Template' },
]

interface CodeEditorModalProps {
    displayName: string
    name: string
    document?: DocumentVersionListItemModel
    onCloseRequest: (canceled: boolean) => void
}

type ActiveEditor = {
    name: CodeEditorTab
    mode: string
    source: string
}

const validateCode = async (editings: EditorDocumentEditingsModel): Promise<boolean> => {
    try {
        if (editings.schema) {
            JSON.parse(editings.schema)
        }
        if (editings.uischema) {
            JSON.parse(editings.uischema)
        }

        return true
    } catch (error) {
        console.log('Invalid code detected', error)
        return false
    }
}

export const CodeEditorModal: React.FC<CodeEditorModalProps> = ({ name, displayName, document, onCloseRequest }) => {

    const [codeEditor, setCodeEditor] = useState<EditorDocumentModel | null>(null)
    const [codeEditings, setCodeEditings] = useState<EditorDocumentEditingsModel | null>(null)
    const [activeTab, setActiveTab] = useState<CodeEditorTab>('schema')
    const [activeEditor, setActiveEditor] = useState<ActiveEditor | null>(null)
    const { observe, height: codeEditorHeight } = useDimensions()

    const getVersionCode = useCallback(async () => {
        if (!document) {
            return
        }
        const code = await RestHelpers.getVersionCode(name, document.version)
        setCodeEditor(code)
        setCodeEditings({
            ...code,
            schema: undefined,
            uischema: undefined,
            template: undefined
        })

    }, [document, name])

    useEffect(() => {
        getVersionCode()
        setActiveTab('schema')
    }, [document, name, getVersionCode])

    useEffect(() => {
        if (!codeEditor) {
            setActiveEditor(null)
            return
        }

        activeTab === 'schema' && setActiveEditor({
            name: activeTab,
            source: codeEditings?.schema || codeEditor.schema,
            mode: 'json'
        })

        activeTab === 'uischema' && setActiveEditor({
            name: activeTab,
            source: codeEditings?.uischema || codeEditor.uischema,
            mode: 'json'
        })

        activeTab === 'template' && setActiveEditor({
            name: activeTab,
            source: codeEditings?.template || codeEditor.template,
            mode: 'json'
        })

    }, [codeEditor, activeTab, codeEditings?.schema, codeEditings?.template, codeEditings?.uischema])

    const updateActiveEditor = (value: string) => {
        setCodeEditings((prevState) => {
            if (!prevState || !activeEditor) {
                return prevState
            }

            return {
                ...prevState,
                schema: activeEditor.name === 'schema' ? value : prevState.schema,
                uischema: activeEditor.name === 'uischema' ? value : prevState.uischema,
                template: activeEditor.name === 'template' ? value : prevState.template,
            }
        })

        setActiveEditor((prevState) => {
            if (!prevState) {
                return prevState
            }

            return {
                ...prevState,
                source: value,
            }
        })
    }

    return (
        <Modal
            canSubmit={true}
            display={!!document}
            submitButtonCaption="Speichern"
            onCancel={() => onCloseRequest(true)}
            onSubmit={async () => {
                if (codeEditings && await validateCode(codeEditings)) {
                    await RestHelpers.saveToDatabase({
                        ...codeEditings,
                        name
                    })
                    onCloseRequest(false)
                }
            }}
        >
            <div className='code-editor'>

                {!activeEditor && <LoadingSpinner color='#EEEEEE'/>}  

                {activeEditor && 
                    <>             
                        <div className='tabs-container'>
                            <Tabs
                                activeTab={activeTab}
                                tabChanged={(newTab) => setActiveTab(newTab)}
                                tabs={codeEditorTabSettings}
                            />
                        </div>


                        <div className='code-editor-box' ref={observe}>
                            <CodeEditor
                                mode={activeEditor.mode}
                                source={activeEditor.source}
                                height={`${codeEditorHeight}px`}
                                name='ace-code-editor'
                                onCodeChanged={(value: string) => updateActiveEditor(value)}
                            />
                        </div>

                        <div className='format-code' onClick={(event) => {
                            event.preventDefault()
                            updateActiveEditor(JSON.stringify(JSON.parse(activeEditor.source), null, 4))
                        }}>Format</div>
                    </>
                }

            </div>
        </Modal>
    )
}
