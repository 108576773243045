import React from 'react'
import '../assets/css/StartPage.css'
import { Link } from "react-router-dom"

import DateHelpers from '../helpers/DateHelpers'

import { FavoriteDocument } from '../model/DocumentListModel'

interface StartPageProps {
    favorites: Array<FavoriteDocument>
}

export const StartPage: React.FC<StartPageProps> = ({ favorites }) => {

    const welcomeText = 'Willkommen zurück, Karolin';

    return (
        <div className='start-page'>
            <div className='start-container'>
                <div className='start-card'>
                    <h1>{welcomeText}</h1>
                    <img className='person-image' src="/person.png" alt={welcomeText}/>
                </div>

                <div className='favorites'>
                    {favorites.length > 0 && <div className='start-text'><h1>Los geht's</h1></div>}

                    <div className='favorites-grid'>
                        {favorites.map(favorite => (
                            <Link key={favorite.name} to={`/doc/${favorite.name}`}>
                                <div className='favorite-item'>
                                    <div className='favorite-icon'/>

                                    <div className='favorite-data'>
                                        <h1>{favorite.displayname}</h1>                            
                                        <p>Zuletzt benutzt: {favorite.lastused ? DateHelpers.getDateStr(favorite.lastused) : '(niemals)'}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}