import React, { useState, useEffect, useCallback } from 'react'
import '../assets/css/VersionList.css'

import RestHelpers from '../helpers/RestHelpers'
import DateHelpers from '../helpers/DateHelpers'

import { VersionListModel, DocumentVersionListItemModel } from '../model/VersionModel'

import { LoadingSpinner } from './LoadingSpinner'
import { CodeEditorModal } from './CodeEditorModal'

interface VersionListProps {
    name: string
    displayName: string
    onRequestReload: () => void
}

export const VersionList: React.FC<VersionListProps> = ({ name, displayName, onRequestReload }) => {

    const [editVersion, setEditVersion] = useState<DocumentVersionListItemModel | undefined>(undefined)
    const [versions, setVersions] = useState<VersionListModel | null>(null)

    const getVersions = useCallback(async () => {
        const versions = await RestHelpers.getVersions(name)
        setVersions(versions)
    }, [name])

    useEffect(() => {
        getVersions()

    }, [name, getVersions])

    if (!versions) {
        return <LoadingSpinner color='#EEEEEE'/>
    }

    return (
        <>
            <CodeEditorModal
                name={name}
                displayName={displayName}
                document={editVersion}
                onCloseRequest={(canceled) => {
                    setEditVersion(undefined)
                    if (!canceled) {
                        getVersions()
                        onRequestReload()
                    }
                }}
            />

            <div className='version-table'>
                {
                    versions.versions.map(version => (
                        <section
                            key={version.version}
                            className="row"
                            onClick={() => setEditVersion(version)}
                        >
                            <div className={`row-item version ${version.isLatest ? 'latest' : ''}`}><span>{version.version}</span></div>
                            <div className='row-item creator'><div className="caption">Bearbeiter:in</div><div>{version.creator}</div></div>
                            <div className='row-item date'><div className="caption">Datum</div><div>{DateHelpers.getDateStr(version.date, true)}</div></div>
                            <div className='row-item edit'><div><p>Bearbeiten</p></div></div>
                        </section>
                    )
                )}
            </div>
        </>
    )
}