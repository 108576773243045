import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from "react-router-dom";

import '../assets/css/DocumentView.css'

import RestHelpers from '../helpers/RestHelpers'

import { DocumentModel } from '../model/DocumentModel'

import { LoadingSpinner } from './LoadingSpinner'
import { DocumentCreate} from './DocumentCreate'
import { DocumentSettings } from './DocumentSettings'
import { DocumentViewHeader } from './DocumentViewHeader'
import { DocumentViewTab, DocumentViewTabSelection } from './DocumentViewTabs'
import { VersionList } from './VersionList'

interface DocumentViewProps {
    onUpdateDocuments: () => void
}

export const DocumentView: React.FC<DocumentViewProps> = ({ onUpdateDocuments }) => {

    let { document } = useParams();

    const [doc, setDoc] = useState<DocumentModel | null>(null)
    const [data, setData] = useState<any | null>(null)    
    const [storedData, setStoredData] = useState<any | null>(null)
    
    const [activeTab, setActiveTab] = useState<DocumentViewTab>('new')
    const [formIsValid, setFormIsValid] = useState<boolean>(false)

    const getDocument = useCallback(async () => {
        if (!document) {
            return
        }

        const d = await RestHelpers.getDocument({name: document, version: -1})
        setDoc(d)

    }, [document])


    useEffect(() => {
        setActiveTab('new')
        getDocument()

    }, [document, getDocument])

    if (!doc) {
        return <LoadingSpinner/>
    }

    return (
        <div className='docuemnt-view-container'>
            <div className='document-view'>
                <DocumentViewHeader
                    downloadEnabled={formIsValid}
                    clearDataEnabled={storedData &&
                        Object.keys(storedData).length !== 0 &&
                        Object.getPrototypeOf(storedData) === Object.prototype}
                    onClearDataClick={() => {
                        setData({})
                        setStoredData({})
                    }}
                    onDownloadClick={() => RestHelpers.downloadPDF({
                        name: doc.name,
                        version: doc.version
                    }, storedData)}
                    name={doc.settings.displayname}
                />

                <div className='document-editor-card'>
                    <DocumentViewTabSelection
                        activeTab={activeTab}
                        tabChanged={(newTab: DocumentViewTab) => {
                            setActiveTab(newTab)
                        }}
                    />

                    <div className='document-view-separator'></div>

                    {activeTab === 'new' && 
                        <div className='document-view-tab-new'>
                            <DocumentCreate
                                data={data}
                                onDataChanged={(data, formIsValid) => {
                                    setStoredData(data)
                                    setFormIsValid(formIsValid)
                                }}
                                document={doc}
                            />
                        </div>
                    }

                    {activeTab === 'versions' && document &&
                        <VersionList
                            name={document}
                            displayName={doc.settings.displayname}
                            onRequestReload={() => getDocument()}
                        />
                    }

                    {activeTab === 'settings' &&
                        <DocumentSettings
                            name={doc.name}
                            settings={doc.settings}
                            onSettingsChanged={(newSettings) => {
                                setDoc((prevState) => {
                                    return prevState ? {
                                        ...prevState,
                                        settings: newSettings
                                    } : null
                                })

                                if (newSettings.displayname !== doc.settings.displayname || newSettings.favorite !== doc.settings.favorite) {
                                    onUpdateDocuments()
                                }
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}