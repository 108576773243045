import React, { useState } from 'react'
import { JsonForms } from '@jsonforms/react'
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers'

import '../assets/css/DocumentSettings.css'

import { DocumentSettingsModel } from '../model/DocumentModel'

import schema from '../model/DocumentSettingsSchema.json'
import uiSchema from '../model/DocumentSettingsUiSchema.json'

import RestHelpers from '../helpers/RestHelpers'
import ObjectHelpers from '../helpers/ObjectHelpers'

interface DocumentSettingsProps {
    name: string
    settings: DocumentSettingsModel
    onSettingsChanged: (newSettings: DocumentSettingsModel) => void
}

const renderers = [
    ...materialRenderers,
    //register custom renderers
];

export const DocumentSettings: React.FC<DocumentSettingsProps> = ({ name, settings, onSettingsChanged }) => {

    const [storedData, setStoredData] = useState<DocumentSettingsModel | undefined>(undefined)

    return (
        <div className='document-settings'>
            <JsonForms
                schema={schema}
                uischema={uiSchema}
                data={settings}
                renderers={renderers}
                cells={materialCells}
                onChange={({ errors, data }) => {
                    const dataValid = 
                        (!errors || errors?.length <= 0) &&
                        !ObjectHelpers.compareFlatObjectValues(settings, data)

                    setStoredData(dataValid ? data : undefined)
                }}
            />

            <div className='save-document-box'>
                <div
                    className={`save-document-button ${!storedData ? 'disabled' : ''}`}
                    onClick={async (event) => {
                        event.preventDefault()
                        if (storedData) {
                            if (await RestHelpers.saveSettingsToDatabase(name, storedData)) {
                                onSettingsChanged(storedData)
                                setStoredData(undefined)
                            }
                        }
                    }}
                >
                    Änderungen übernehmen
                </div>
            </div>
        </div>
    )
}