
import { DocumentListModel } from '../model/DocumentListModel'
import { DocumentModel, StoreDocumentModel, DocumentDefinition, EditorDocumentModel, DocumentSettingsModel } from '../model/DocumentModel'
import { VersionListModel } from '../model/VersionModel'
import { PDFRendererResponse } from '../model/Responses'

const saveToDatabase = async (model: StoreDocumentModel): Promise<DocumentDefinition> => {
    try {

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/doc`, {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(model)
        })                   

        const data = await response.json()

        return Promise.resolve(data as DocumentDefinition)

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }

        console.log('saveToDatabase failed', errorMessage);

        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        })*/

        return Promise.reject()
    }

}

const saveSettingsToDatabase = async (name: string, model: DocumentSettingsModel): Promise<boolean> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/doc/settings/${name}`, {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(model)
        })

        const data = await response.json()
        console.log('Save response', data)
        return Promise.resolve(true)

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }
        
        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        })*/

        console.log('saveSettingsToDatabase failed', errorMessage)

        return Promise.reject()
    }
}

const getDocumentList = async (): Promise<DocumentListModel> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/docs`)
        return await (response.json() as Promise<DocumentListModel>)
    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }
        
        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        })*/

        console.log('getDocumentList failed', errorMessage)

        return Promise.reject()
    }
}

const downloadPDF = async (doc: DocumentDefinition, data: any) => {

    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    try {
        const payload = JSON.stringify({
            inline: false,
            formdata: data
        })
        
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/doc/render/${doc.name}`, {
            method: 'POST',
            headers: {'Accept': 'application/pdf'},
            body: payload
        })            
        
        const json = await (response.json() as Promise<PDFRendererResponse>)

        const file = b64toBlob(json.data, 'application/pdf')
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', json.filename);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }
        
        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        })*/

        console.log('downloadPDF failed', errorMessage)

        return Promise.reject()
    }
}

const getDocument = async (document: DocumentDefinition): Promise<DocumentModel> => {

    try {
        let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/doc/${document.name}`)
        return await (response.json() as Promise<DocumentModel>)

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }

        console.log('getDocument failed', errorMessage);

        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        }) */   
        
        return Promise.reject()
    }

}

const getVersions = async (document: string): Promise<VersionListModel> => {

    try {
        let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/versions/${document}`)
        return await (response.json() as Promise<VersionListModel>)

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }

        console.log('getVersions failed', errorMessage);

        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        }) */   
        
        return Promise.reject()
    }

}

const getVersionCode = async (document: string, version: number): Promise<EditorDocumentModel> => {

    try {
        let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/versions/${document}/${version}`)
        return await (response.json() as Promise<EditorDocumentModel>)

    } catch (error) {
        let errorMessage
        if (typeof error === "string") {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message 
        }

        console.log('getVersionCode failed', errorMessage);

        /*onStatusUpdate({
            message: 'Ein Fehler ist aufgetreten',
            error: errorMessage
        }) */   
        
        return Promise.reject()
    }

}

const exportedObject = {
    getDocument,
    getDocumentList,
    downloadPDF,
    saveToDatabase,
    saveSettingsToDatabase,
    getVersions,
    getVersionCode
}

export default exportedObject