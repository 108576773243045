import React from 'react'
import '../assets/css/AppHeader.css'

interface AppHeaderProps {
    name: string
    onLogoClick: () => void
}

export const AppHeader: React.FC<AppHeaderProps> = ({ name, onLogoClick }) => {


    return (
        <div className='app-header' onClick={(event) => {
            event.preventDefault()
            onLogoClick()
        }}>
            <img src="/logo.png" alt={name}/>
        </div>
    )
}