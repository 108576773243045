
import React, { useEffect, useState } from 'react'
import '../assets/css/LoadingSpinner.css'

interface LoadingSpinnerProps {
    size?: number
    color?: string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size, color }) => {

    const [ringSize, setRingSize] = useState<string>('64px')
    const [innerRingSize, setInnerRingSize] = useState<string>('52px')
    const [borderSize, setBorderSize] = useState<string>('8px')

    useEffect(() => {
        setRingSize(size ? `${size}px` : '64px')
        setInnerRingSize(size ? `${size * 0.8}px` : '52px')
        setBorderSize(size ? `${size * 0.1}px` : '8px')
    }, [size])

    return (
        <div className="loading-spinner">
            <div className="loading-spinner-ring" style={{
                width: ringSize,
                height: ringSize
            }}>
                <div style={{
                    width: innerRingSize,
                    height: innerRingSize,
                    margin: borderSize,
                    border: `${borderSize} solid ${color || '#fff'}`,
                    borderColor: `${color || '#fff'} transparent transparent transparent`,
                }}></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
