import { useEffect, useState } from 'react';
import '../assets/css/Tabs.css';

export interface Tab<T extends string> {
    name: T
    title: string
    rightAligned?: boolean
}

type TabsArray<T extends string> = Array<Tab<T>>

interface TabProps<T extends string> {
    tabs: TabsArray<T>
    activeTab: T
    tabChanged: (newTab: T) => void
}

export const Tabs = <T extends string>({ tabs, activeTab, tabChanged }: TabProps<T>) => {

    const [leftTabs, setLeftTabs] = useState<TabsArray<T> | undefined>(undefined)
    const [rightTabs, setRightTabs] = useState<TabsArray<T> | undefined>(undefined)

    useEffect(() => {
        const getTabGroups = (tabs: TabsArray<T>): [TabsArray<T>, TabsArray<T>] => {
            const leftTabs: TabsArray<T> = []
            const rightTabs: TabsArray<T> = []
            tabs.forEach(tab => {
                if (tab.rightAligned) {
                    rightTabs.push(tab)
                } else {
                    leftTabs.push(tab)
                }
            });
        
            return [leftTabs, rightTabs]
        }

        const [left, right] = getTabGroups(tabs)
        setLeftTabs(left)
        setRightTabs(right)
    }, [tabs])
    
    
    const createTab = (tab: Tab<T>): JSX.Element => (
        <div
            className={`tab-item ${activeTab === tab.name ? "selected" : ""}`}
            key={tab.name}
            onClick={(event) => {
                event.preventDefault()
                tabChanged(tab.name)
            }}
        >
            {tab.title}
        </div>
    )

    return (
        <div className='tabs'>
            <div className='tabs-left'>
                {leftTabs && leftTabs.map(tab => createTab(tab))}
            </div>
            <div className='tabs-right'>
                {rightTabs && rightTabs.map(tab => createTab(tab))}
            </div>
        </div>
    )
}