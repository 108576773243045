import React from 'react'
import '../assets/css/Modal.css'
import { CSSTransition } from 'react-transition-group'

interface ModalProps {
    display: boolean
    canSubmit: boolean
    onCancel: () => void
    onSubmit: () => void
    submitButtonCaption: string
}

export const Modal: React.FC<ModalProps> = ({ children, display, canSubmit, onCancel, onSubmit, submitButtonCaption }) => {

    return (

        <CSSTransition
            in={display}
            unmountOnExit
            classNames="modal-container"
            timeout={225}
        >
            <div className='modal-container'>
                <div className='modal-background'></div>
                <div className='modal-dialog'>
                    <div>
                        {children}
                    </div>
                    <div className='modal-buttons'>
                        <button className='modal-button cancel' onClick={(event) => {
                            event.preventDefault()
                            onCancel()
                        }}>Abbrechen</button>
                        <button
                            className={`modal-button ${!canSubmit ? 'disabled' : ''}`}
                            disabled={!canSubmit}
                            onClick={(event) => {
                                event.preventDefault()
                                if (canSubmit) {
                                    onSubmit()
                                }
                            }
                        }>
                            {submitButtonCaption}
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}