import React from 'react'
import '../assets/css/DocumentList.css'

import { useParams } from "react-router-dom";

import { SelectableDocument } from '../model/DocumentListModel'

interface DocumentListProps {
    documents: Array<SelectableDocument>

    onDocumentSelected: (newDocument: string) => void
    onAddDocumentTemplateClick: () => void
}

export const DocumentList: React.FC<DocumentListProps> = ({ documents, onDocumentSelected, onAddDocumentTemplateClick }) => {

    let { document } = useParams();

    return (
        <div className='document-list'>
            <div className='document-list-view'>
                {documents.map(doc => 
                    <div
                        className={`document-list-item ${document === doc.name ? "selected" : ""}`}
                        key={doc.name}
                        onClick={(event) => {
                            event.preventDefault()
                            onDocumentSelected(doc.name)
                        }}
                    >
                        <div className='document-list-item-icon'></div>
                        <span>{doc.displayname}</span>
                        {document === doc.name && <div className='document-list-item-selection'></div>}
                    </div>
                )}
            </div>
            <div className='document-list-bottom'>
                <button onClick={(event) => {
                    event.preventDefault()
                    onAddDocumentTemplateClick()
                }}><span>+</span></button>
            </div>
        </div>
    )
}