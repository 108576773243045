const compareFlatObjectValues = (right: Object, left: Object): boolean => {

    const typedKeys = <T>(o: T): (keyof T)[] => {
        return Object.keys(o) as (keyof T)[];
    }

    const rightKeys = typedKeys(right)
    const leftKeys = typedKeys(left)

    if (rightKeys.length !== leftKeys.length) {
        return false
    }

    return rightKeys.every(key => ((left[key] !== undefined && left[key] === right[key])))

}

const exportedObject = {
    compareFlatObjectValues
}

export default exportedObject