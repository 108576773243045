import React, { useState, useCallback, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import useDimensions from "react-cool-dimensions"
import { CSSTransition } from 'react-transition-group'

import './assets/css/App.css'

import RestHelpers from './helpers/RestHelpers'

import { defaultModel } from './model/DefaultDocument'
import { FavoriteDocument, SelectableDocument } from './model/DocumentListModel'
import { AppDisplayMode } from './model/AppDisplayModel'

import { AppHeader } from './components/AppHeader'
import { DocumentList } from './components/DocumentList'
import { DocumentView } from './components/DocumentView'
import { Modal } from './components/Modal'
import { StartPage } from './components/StartPage'


interface HomeProps {
    documents: Array<SelectableDocument>
    displayMode: AppDisplayMode,
    onChangeDisplayMode: (newDisplayMode: AppDisplayMode) => void
    onUpdateDocuments: () => void
}

const Home: React.FC<HomeProps> = ({ documents, displayMode, onChangeDisplayMode, onUpdateDocuments }) => {

    const [addNewDocument, setAddNewDocument] = useState<boolean>(false)
    const [newDocumentName, setNewDocumentName] = useState<string | undefined>(undefined)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    let navigate = useNavigate();


    const { observe } = useDimensions({
        breakpoints: { mobile: 0, desktop: 850 },
        updateOnBreakpointChange: true,
        useBorderBoxSize: true,
        onResize: ({ currentBreakpoint }) => {
            onChangeDisplayMode(currentBreakpoint as AppDisplayMode)
        },
    })

    return (
        <>
            <Modal
                display={addNewDocument}
                submitButtonCaption='Einfügen'
                onSubmit={async () => {
                    setAddNewDocument(false)
                    if (!!newDocumentName) {
                        let document = await RestHelpers.saveToDatabase({
                            ...defaultModel,
                            displayname: newDocumentName,
                        })
                        onUpdateDocuments()
                        navigate(`/doc/${document.name}`)
                        setNewDocumentName(undefined)
                    }
                }}
                onCancel={() => setAddNewDocument(false)}
                canSubmit={!!newDocumentName && newDocumentName !== ''}
            >
                <div className="add-new-document">
                    <h1>Neue Dokumentenvorlage einfügen</h1>
                    <input type="text" placeholder='Vorlagenname' onChange={(event) => {
                        event.preventDefault()
                        setNewDocumentName(event.target.value)
                    }}></input>
                </div>
            </Modal>

            <div className='app' ref={observe}>
                <div className='shadow-container'>
                    <div className='main-view'>
                        <CSSTransition
                            in={displayMode !== 'mobile' || showMenu}
                            timeout={displayMode === 'mobile' ? 225 : 0}
                            classNames={displayMode === 'mobile' ? 'main-sidebar' : undefined}
                            unmountOnExit
                        >
                            <div className='main-sidebar'>
                                <div className='main-sidebar-navigation'>
                                    <AppHeader name="KSDocs" onLogoClick={() => {
                                        setShowMenu(false)
                                        navigate('/')
                                    }}/>
                                    <DocumentList
                                        documents={documents}
                                        onDocumentSelected={(newDocument: string) => {
                                            setShowMenu(false)
                                            navigate(`/doc/${newDocument}`)
                                        }}                                        
                                        onAddDocumentTemplateClick={() => setAddNewDocument(true)}
                                    />
                                </div>

                                <div className='main-view-spacer'></div>
                            </div>

                        </CSSTransition>

                        <CSSTransition
                            in={displayMode === 'mobile' && showMenu}
                            timeout={225}
                            classNames="sidebar-overlay"
                            unmountOnExit
                        >
                            <div
                                className='sidebar-overlay'
                                onClick={(event) => {
                                    event.preventDefault()
                                    setShowMenu(false)
                                }}
                            >
                            </div>

                        </CSSTransition>

                        {displayMode === 'mobile' && !showMenu &&
                            <div
                                className='menu-button'
                                onClick={(event) => {
                                    event.preventDefault()
                                    setShowMenu(true)
                                }}
                            >

                            </div>
                        }

                        <Outlet/>
                    </div>
                </div>
            </div>
        </>

    )
};

const App = () => {

    const [displayMode, setDisplayMode] = useState<AppDisplayMode>('mobile')

    const [documents, setDocuments] = useState<Array<SelectableDocument>>([])
    const [favorites, setFavorites] = useState<Array<FavoriteDocument>>([])

    const getDocuments = useCallback(async () => {
        const documents = await RestHelpers.getDocumentList()
        setDocuments(documents.documents)
        setFavorites(documents.favorites)
    }, [])

    useEffect(() => {
        getDocuments()
    }, [getDocuments])


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home
                    documents={documents}
                    displayMode={displayMode}
                    onChangeDisplayMode={(newDisplayMode) => setDisplayMode(newDisplayMode)}
                    onUpdateDocuments={() => getDocuments()}
                />}>
                    <Route index element={<StartPage
                        favorites={favorites}
                    />} />
                    <Route path="/doc/:document" element={
                        <DocumentView
                            onUpdateDocuments={() => getDocuments()}
                        />}
                    />
                </Route>                
            </Routes>
        </BrowserRouter>
    )
}

export default App;
