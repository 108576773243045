
const getDateStr = (date: number, addTime: boolean = false): string => {
    const t = new Date()
    const today = new Date(t.getFullYear(), t.getMonth(), t.getDate())
    const d = new Date(date * 1000)
    const favoriteDate = new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime()

    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime()
    const twoDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2).getTime()

    let result = d.toLocaleDateString('de-DE')

    if (today.getTime() === favoriteDate) {
        result = 'Heute'
    } else if (yesterday === favoriteDate) {
        result = 'Gestern'
    } else if (twoDaysAgo === favoriteDate) {
        result = 'Vor zwei Tagen'
    }

    return result + (addTime ? `, ${d.toLocaleTimeString('de-DE')}` : '')
}

const exportedObject = {
    getDateStr
}

export default exportedObject
