import React, { useState } from 'react'
import '../assets/css/DocumentViewHeader.css'

import { Modal } from './Modal'

interface DocumentViewHeaderProps {
    name: string
    downloadEnabled: boolean
    clearDataEnabled: boolean
    onClearDataClick: () => void
    onDownloadClick: () => void
}

export const DocumentViewHeader: React.FC<DocumentViewHeaderProps> = ({
    name, downloadEnabled, clearDataEnabled,
    onClearDataClick, onDownloadClick }) => {

    const [showClearConfirmation, setShowClearConfimation] = useState<boolean>(false)
    
    return (
        <div className='document-view-header'>

            <Modal
                display={showClearConfirmation}
                canSubmit={true}
                submitButtonCaption='Zurücksetzen'
                onCancel={() => setShowClearConfimation(false)}
                onSubmit={() => {
                    setShowClearConfimation(false)
                    onClearDataClick()
                }}
            >
                <p>Sind Sie sicher, dass Sie alle Eingaben für die akutelle Vorlage zurücksetzen möchten?</p>
            </Modal>

            <h1>{name}</h1>
            
            <div className='document-view-header-right'>
                <button
                    className={`document-button clear-data ${!clearDataEnabled ? 'disabled' : ''}`}
                    onClick={(event) => {
                        if (!clearDataEnabled) {
                            return
                        }
                        event.preventDefault()
                        setShowClearConfimation(true)
                    }}
                    disabled={!clearDataEnabled}
                >
                    <div className='icon trash'></div><span>Eingaben zurücksetzen</span>
                </button>
                <button
                    className={`document-button ${!downloadEnabled ? 'disabled' : ''}`}
                    onClick={(event) => {
                        if (!downloadEnabled) {
                            return
                        }
                        event.preventDefault()
                        onDownloadClick()
                    }}
                    disabled={!downloadEnabled}
                >
                    <div className='icon'></div><span>PDF herunterladen</span>
                </button>
            </div>
        </div>
    )
}