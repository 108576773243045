import React from 'react';
import '../assets/css/DocumentViewTabs.css'

import { Tabs, Tab } from './Tabs'

const documentViewTabs = ['new', 'settings', 'versions'] as const
export type DocumentViewTab = typeof documentViewTabs[number]

interface DocumentViewTabSelectionProps {
    activeTab: DocumentViewTab
    tabChanged: (newTab: DocumentViewTab) => void
}

const documentViewTabSettings: Array<Tab<DocumentViewTab>> = [
    { name: 'new', title: 'Neues Dokument' },
    { name: 'settings', title:  'Einstellungen', rightAligned: true },
    { name: 'versions', title:  'Versionen', rightAligned: true },
]

export const DocumentViewTabSelection: React.FC<DocumentViewTabSelectionProps> = ({ activeTab, tabChanged }) => {

    return (
        <div className='document-view-tabs-container'>
            <Tabs
                activeTab={activeTab}
                tabChanged={(newTab) => tabChanged(newTab as DocumentViewTab)}
                tabs={documentViewTabSettings}
            />
        </div>
    )
}