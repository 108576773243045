import React from 'react';
import { JsonForms } from '@jsonforms/react'
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers'
import '../assets/css/DocumentCreate.css'
import { DocumentModel } from '../model/DocumentModel'

interface DocumentCreateProps {
    data: any,
    document: DocumentModel
    onDataChanged: (data: any, formIsValid: boolean) => void
}
  
const renderers = [
    ...materialRenderers,
    //register custom renderers
];

export const DocumentCreate: React.FC<DocumentCreateProps> = ({ document, data, onDataChanged }) => {

    return (
        <div className='document-container'>
            <JsonForms
                schema={document.schema}
                uischema={document.uischema}
                data={data}
                renderers={renderers}
                cells={materialCells}
                onChange={({ errors, data }) => {
                    onDataChanged(data, errors ? errors.length <= 0 : true)
                }}
            />
        </div>
    )
}