import { JsonSchema, Scopable, UISchemaElement } from '@jsonforms/core'
import { StoreDocumentModel } from './DocumentModel'

export const defaultModel: StoreDocumentModel = {
    name: '',
    displayname: '',
    schema: JSON.stringify({
        properties: {
            name: {
                type: "string"
            }
        }

    } as JsonSchema, null, 4),
    uischema: JSON.stringify({
        type: "Control",
        scope: "#/properties/name"
    } as UISchemaElement & Scopable, null, 4),
    template: '<html><head></head><body></body></html>',
}