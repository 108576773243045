import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';

/**
 * Customize form so each control has more space
 */
const theme = createTheme({
    typography: {
        fontFamily: 'floatingFont'
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: '0.8em 0',
                },
            }
        },
    },
});

ReactDOM.render(
    <CookiesProvider>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </CookiesProvider>,
    document.getElementById('root')
);
