import React from 'react'
import AceEditor from "react-ace"

import 'brace/mode/html'
import 'brace/mode/json'
import 'brace/theme/github'
import 'brace/ext/language_tools'

interface CodeEditorProps {
    source: string
    mode: string
    name: string
    height: string
    onCodeChanged: (value: string) => void
}

export const CodeEditor: React.FC<CodeEditorProps> = ({ source, mode, name, height, onCodeChanged }) => {

    return (
        <AceEditor
            mode={mode}
            theme="github"
            name={name}
            value={source}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false
            }}
            width="100%"
            height={height}
            onChange={(value) => onCodeChanged(value)}
        />
    )
}